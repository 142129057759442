/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_content_delivery_bucket": "ixtimapsamplify-20210522131839-hostingbucket-dev",
    "aws_content_delivery_bucket_region": "us-east-1",
    "aws_content_delivery_url": "https://d102pj8wx6nunq.cloudfront.net",
    "aws_cognito_identity_pool_id": "us-east-1:876f8fa5-c3d0-429f-b547-6fc5a6fdb2cc",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_vCon1gyX6",
    "aws_user_pools_web_client_id": "5ud5ebkhvj327bc7c3jem3621p",
    "oauth": {},
    "aws_mobile_analytics_app_id": "f10cf05b7b4a49cb8f7dcc9da192c739",
    "aws_mobile_analytics_app_region": "us-east-1"
};


export default awsmobile;
